import BeatLoader from "react-spinners/BeatLoader"
const CustomLoader = (props) => {
  const { type, style, isLoading, ...otherProps } = props
  return (
    <>
      {isLoading && (
        <BeatLoader style={style} color="#36d7b7" loading={isLoading} />
      )}
    </>
  )
}
export default CustomLoader
