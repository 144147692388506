import { useEffect, useState } from "react"
import { Alert, Button, Table } from "react-bootstrap"
import Timer from "./Timer"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const Perkalian = () => {
  const history = useHistory()
  const [angka1, setAngka1] = useState(1)
  const [angka2, setAngka2] = useState(2)
  const [waktu, setWaktu] = useState("")
  const [hasil, setHasil] = useState(null)
  const [isGameStart, setIsGameStart] = useState(false)
  const [index, setIndex] = useState(0)

  const [listNumber, setListNumber] = useState([])

  const getRandomInt = (max) => {
    let n = Math.floor(Math.random() * max)
    if (n == 0) return 8
    if (n >= 0 && n <= 1) n = n + 2
    return n
  }

  const generateNumber = () => {
    let l = []
    for (let i = 1; i <= 10; i++) {
      l.push([getRandomInt(10), getRandomInt(10)])
    }
    setListNumber(l)
  }
  const onStopGame = () => {
    setIsGameStart(false)
  }
  const onNextGame = () => {
    if (index + 1 < 10) {
      setIndex(index + 1)
    } else {
      onStopGame()
    }
  }
  const onRestartGame = () => {
    setIsGameStart(true)
    generateNumber()
    setIndex(0)
  }
  useEffect(() => {
    if (listNumber.length != 0) {
      console.log("index " + index)
      console.log(listNumber)
      let pasang = listNumber[index]
      setAngka1(pasang[0])
      setAngka2(pasang[1])
    }
  }, [listNumber, index])

  useEffect(() => {
    setWaktu(Date())
  }, [listNumber, index])
  useEffect(() => {
    generateNumber()
  }, [])
  return (
    <>
      <Table>
        <tbody>
          <tr>
            <td colSpan={1}>
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  history.push("/")
                }}
              >
                Kembali
              </Button>{" "}
            </td>
          </tr>
          <tr>
            <td colSpan={1}>
              {!isGameStart && (
                <Button className="primary btn-sm" onClick={onRestartGame}>
                  Mulai
                </Button>
              )}
              {isGameStart && (
                <>
                  <Button className="danger  btn-sm" onClick={onNextGame}>
                    Soal Selanjutnya
                  </Button>
                </>
              )}
            </td>
            <td colSpan={4}>
              <Timer start={isGameStart} cek={Math.random()}></Timer>
            </td>
          </tr>
          {isGameStart && (
            <>
              <tr className="papan_soal" style={{ "text-align": "center" }}>
                <td colSpan={5}>
                  {" "}
                  {angka1} * {angka2}{" "}
                </td>
              </tr>
              <tr>
                <td colSpan={1}>{isGameStart && <>SOAL KE - {index + 1}</>}</td>
                <td colSpan={4}></td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    </>
  )
}

export default Perkalian
