import { useState } from "react"
import { Button, Container, FormControl, Table } from "react-bootstrap"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const RandomMultiply = () => {
  const history = useHistory()
  const [min, setMin] = useState(22)
  const [max, setMax] = useState(39)
  const [angka1, setAngka1] = useState()
  const [angka2, setAngka2] = useState()
  const [hasil, setHasil] = useState()
  const [tampilJawaban, setTampilJawaban] = useState(false)
  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  const hitung = () => {
    let angka1 = randomIntFromInterval(Number(min), Number(max))
    let angka2 = randomIntFromInterval(Number(min), Number(max))
    setAngka1(angka1)
    setAngka2(angka2)
    setHasil(angka1 * angka2)
  }
  const acak = () => {
    setTampilJawaban(false)
    hitung()
  }
  const onTampilkanJawaban = () => {
    setTampilJawaban(true)
  }
  return (
    <div className="App">
      <Container>
        <Table
          aria-label="simple table"
          style={{ fontSize: "50px", fontWeight: "bold" }}
          className=" table-stripped"
        >
          <tbody>
            <tr>
              <td colSpan={1} style={{ float: "left", textAlign: "left" }}>
                <Button
                  color="primary"
                  size="xl"
                  onClick={() => {
                    history.push("/")
                  }}
                >
                  Kembali
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Button
                  color="primary"
                  size="xl"
                  onClick={() => {
                    acak()
                  }}
                >
                  Acak
                </Button>
              </td>
            </tr>
            <tr>
              <td>
                Min
                <FormControl
                  variant="filled"
                  type="number"
                  value={min}
                  onChange={(e) => {
                    setMin(e.target.value)
                  }}
                />
              </td>
              <td>
                Max
                <FormControl
                  variant="filled"
                  type="number"
                  value={max}
                  onChange={(e) => {
                    setMax(e.target.value)
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>{angka1}</td>
              <td>{angka2}</td>
              <td>{tampilJawaban && <>{hasil}</>}</td>
              <td>
                <Button
                  color="primary"
                  size="lg"
                  onClick={() => {
                    onTampilkanJawaban()
                  }}
                >
                  Tampilkan Jawaban
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </div>
  )
}

export default RandomMultiply
