import { Button } from "react-bootstrap"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const Homepage = () => {
  const history = useHistory()
  const onPerkalian10detik = () => {
    history.push("/perkalian-10-detik")
  }
  const onPerkalianMinMax = () => {
    history.push("/perkalian-min-max")
  }
  return (
    <>
      <Button onClick={onPerkalian10detik} color="primary">
        Perkalian 10 detik
      </Button>{" "}
      <Button onClick={onPerkalianMinMax} color="primary">
        Perkalian MinMax
      </Button>
    </>
  )
}
export default Homepage
