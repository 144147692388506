import { Card, CardBody, CardHeader } from "react-bootstrap"
import RemoteBootstrapDataTable from "../../Components/RemoteBootstrapDataTable"
import {
  FaCalendar,
  FaClipboardList,
  FaEdit,
  FaEye,
  FaPlus,
  FaRemoveFormat,
} from "react-icons/fa"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { QuizApi } from "../../Api/quiz"
import { useState } from "react"
import { Button } from "reactstrap"
const AdminGridQuiz = () => {
  const history = useHistory()
  const [keyWord, setKeyword] = useState()
  const [reloadGrid, setReloadGrid] = useState(false)
  const columns = [
    { dataField: "title", text: "Title" },
    {
      dataField: "x",
      text: "#",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <>
            <Button
              color="primary"
              onClick={() => {
                onEdit(row.uuid)
              }}
            >
              <FaEdit></FaEdit>
            </Button>
            <Button
              color="primary"
              onClick={() => {
                onGridQuestions(row.uuid)
              }}
            >
              <FaClipboardList />
            </Button>
            <Button
              color="danger"
              onClick={() => {
                onDelete(row.uuid)
              }}
            >
              <FaRemoveFormat />
            </Button>
          </>
        )
      },
    },
  ]
  const onGridQuestions = (uuid) => {
    history.push("/admin/quiz/" + uuid + "/questions")
  }
  const onTambah = () => {
    history.push("/admin/quiz/tambah")
  }
  const onEdit = (uuid) => {
    history.push("/admin/quiz/" + uuid)
  }
  const onDelete = (uuid) => {
    QuizApi.delete(uuid)
      .then((res) => {})
      .finally(() => {
        setReloadGrid(true)
      })
  }
  const onRequest = (page) => {
    return QuizApi.list(page, keyWord)
  }
  return (
    <Card>
      <CardHeader>
        Admin Quiz
        <Button onClick={onTambah} style={{ float: "right" }}>
          <FaPlus color="primary"></FaPlus> Quiz Baru
        </Button>
      </CardHeader>
      <CardBody>
        <RemoteBootstrapDataTable
          reloadGrid={true}
          setReloadGrid={setReloadGrid}
          columns={columns}
          onPaging={onRequest}
        ></RemoteBootstrapDataTable>
      </CardBody>
    </Card>
  )
}
export default AdminGridQuiz
