import { Button } from "react-bootstrap"
import CustomLoader from "./CustomLoader"

const ButtonProgress = (props) => {
  const { isLoading, title, ...otherProps } = props
  return (
    <Button {...otherProps} disabled={isLoading}>
      <CustomLoader isLoading={isLoading} />
      {!isLoading && <>{title}</>}
    </Button>
  )
}
export default ButtonProgress
