import { useEffect, useRef, useState } from "react"
import { Alert, Button, Card, CardBody, Row } from "reactstrap"
import { VscDebugDisconnect } from "react-icons/vsc"
import CustomLoader from "./CustomLoader"

const RemoteBootstrapDataTable = ({
  keyField,
  columns,
  onPaging,
  remote,
  reloadGrid,
  setReloadGrid,
  filterForm,
  ...otherProps
}) => {
  const [ref, setRef] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState([])
  const [lastPage, setLastPage] = useState()
  const [page, setPage] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [totalSize, setTotalSize] = useState(0)
  const [cRef, setCRef] = useState()

  const onTableChange = (r, i) => {
    onRequest(i.page)
  }
  const buildFooter = () => {
    if (lastPage < 1) return <></>
    if (lastPage == 1)
      return (
        <>
          <p></p>
        </>
      )
    let pages = Array(lastPage)
    return pages.map((v, i) => {
      return <div key={i}>s{i}</div>
    })
  }
  const onRequest = (page) => {
    setIsLoading(true)
    setIsError(false)
    setReloadGrid(false)
    onPaging(page)
      .then((res) => {
        setIsError(false)
        setIsLoading(false)
        setPage(res?.data?.current_page)
        setLastPage(Number(res?.data?.last_page))
        setSizePerPage(res?.data?.per_page)
        setTotalSize(res?.data?.total)
        setData(res?.data?.data)
      })
      .catch((error) => {
        setIsError(true)
        setIsLoading(false)
      })
    setReloadGrid(false)
  }
  useEffect(() => {
    if (reloadGrid) onRequest(1)
  }, [reloadGrid])
  return (
    <>
      <Card>
        <CardBody>
          <div className="text-center">
            {isError && (
              <Alert color="primary">
                <VscDebugDisconnect size={"2em"} />
                Tidak dapat terhubung ke server
              </Alert>
            )}
          </div>
          {filterForm && <>{filterForm()}</>}
          <table className="table table-striped table-hover table-bordered table-condensed">
            <thead>
              <tr>
                {columns.map((v, i) => {
                  return (
                    <th key={i} style={v?.style}>
                      {v.text}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr style={{ textAlign: "center" }}>
                  <td colSpan={data.length}>
                    <CustomLoader isLoading={isLoading} />
                  </td>
                </tr>
              )}

              {!isLoading &&
                !isError &&
                data &&
                data.length <= 0 &&
                otherProps?.emptyData && (
                  <tr>
                    <td colSpan={data.length}>{otherProps?.emptyData()}</td>
                  </tr>
                )}
              {!isLoading &&
                !isError &&
                data.map((r, ir) => {
                  return (
                    <tr key={ir}>
                      {columns.map((c, ic) => {
                        let cellValue = r[c.dataField]

                        if (c.formatter) {
                          cellValue = c.formatter(null, r, ir, {})
                        }
                        return <td key={ic}>{cellValue}</td>
                      })}
                    </tr>
                  )
                })}
            </tbody>
          </table>
          {!isLoading && !isError && lastPage > 1 && (
            <Row
              className="react-bootstrap-table-pagination-list "
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div className="pagination">
                {Array(lastPage)
                  .fill("")
                  .map((v, i) => {
                    let cPage = i + 1
                    let active = false
                    if (cPage == page) active = true
                    return (
                      <li
                        key={i}
                        className={active ? "active page-item" : "page-item"}
                        title="1"
                      >
                        <a
                          onClick={() => {
                            if (!active) onRequest(cPage)
                          }}
                          href="#"
                          className="page-link"
                        >
                          {cPage}
                        </a>
                      </li>
                    )
                  })}
              </div>
            </Row>
          )}
        </CardBody>
      </Card>
    </>
  )
}
export default RemoteBootstrapDataTable
