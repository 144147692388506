import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  FormGroup,
} from "react-bootstrap"
import RemoteBootstrapDataTable from "../../Components/RemoteBootstrapDataTable"
import {
  FaArrowLeft,
  FaCalendar,
  FaEdit,
  FaEye,
  FaPlus,
  FaRemoveFormat,
  FaSave,
} from "react-icons/fa"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { useEffect, useState } from "react"
import { QuizApi } from "../../Api/quiz"
import CustomLoader from "../../Components/CustomLoader"
import { Input, Label } from "reactstrap"
import ButtonProgress from "../../Components/ButtonProgress"
const CustomComponent = () => {
  const history = useHistory()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState()
  const [saveLoading, setSaveLoading] = useState(false)
  const [fields, setFields] = useState({
    title: "",
  })
  const copyIntoFields = (data) => {
    Object.keys(fields).forEach((item) => {
      if (data && data.hasOwnProperty(item)) {
        fields[item] = data[item]
      }
    })
    setFields({ ...fields })
  }
  const onBack = () => {
    history.push("/admin/quiz")
  }
  const onChange = (e, key) => {
    fields[key] = e.target.value
    setFields({ ...fields })
  }
  const onSave = () => {
    setSaveLoading(true)
    QuizApi.update(params.uuid_quiz, {
      data: JSON.stringify(fields),
    })
      .then((res) => {})
      .finally(() => {
        setSaveLoading(false)
      })
  }
  useEffect(() => {
    setIsLoading(true)
    QuizApi.edit(params.uuid_quiz)
      .then((res) => {
        setData(res?.data)
        copyIntoFields(res?.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])
  return (
    <Card>
      <CardHeader>
        Form Admin Quiz Edit
        <Button onClick={onBack} style={{ float: "right" }}>
          <FaArrowLeft color="primary"></FaArrowLeft> Kembali
        </Button>
      </CardHeader>
      <CardBody>
        <CustomLoader isLoading={isLoading} />
        {!isLoading && (
          <>
            <Form>
              <FormGroup>
                <Label>Judul</Label>
                <Input
                  type="text"
                  value={fields.title}
                  onChange={(e) => onChange(e, "title")}
                ></Input>
              </FormGroup>
            </Form>
          </>
        )}
      </CardBody>
      <CardFooter>
        <ButtonProgress
          onClick={onSave}
          title={"Simpan"}
          isLoading={saveLoading}
        ></ButtonProgress>
      </CardFooter>
    </Card>
  )
}
export default CustomComponent
