import { ApiCall } from "./api"

const listQuestion = async (uuid_quiz, page) => {
  return await ApiCall.get(
    `/admin/quiz/${uuid_quiz}/questions?page=` + page
  ).then((res) => {
    return res
  })
}
const editQuestion = async (id) => {
  return await ApiCall.get(`/admin/questions/${id}/edit`).then((res) => {
    return res
  })
}
const updateQuestion = async (id, fields) => {
  const formData = new FormData()
  Object.keys(fields || {}).forEach((item) => {
    formData.append(item, fields[item])
  })
  formData.append("_method", "PATCH")
  return await ApiCall.post(`/admin/questions/${id}`, formData, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  }).then((res) => {
    return res
  })
}
const deleteQuestion = async (id) => {
  return await ApiCall.delete(`/admin/questions/${id}`).then((res) => {
    return res
  })
}
const addQuestion = async (formData) => {
  await ApiCall.post(`/admin/questions`, formData).then((res) => {
    return res
  })
}
export const QuestionApi = {
  list: listQuestion,
  edit: editQuestion,
  add: addQuestion,
  update: updateQuestion,
  delete: deleteQuestion,
}
