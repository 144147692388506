import { Button, Card, CardBody, CardHeader, Col, Row } from "react-bootstrap"
import RemoteBootstrapDataTable from "../../Components/RemoteBootstrapDataTable"
import {
  FaCalendar,
  FaClipboardList,
  FaEdit,
  FaEye,
  FaPlus,
  FaRemoveFormat,
} from "react-icons/fa"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { QuizApi } from "../../Api/quiz"
import { useState } from "react"
import { QuestionApi } from "../../Api/question"
const CustomComponent = () => {
  const history = useHistory()
  const params = useParams()

  const [keyWord, setKeyword] = useState()
  const [reloadGrid, setReloadGrid] = useState(false)
  const columns = [
    { dataField: "question", text: "Question" },
    {
      dataField: "x",
      text: "#",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <>
            <Button
              color="primary"
              onClick={() => {
                onEdit(row.uuid)
              }}
            >
              <FaEdit></FaEdit>
            </Button>
            <Button
              color="primary"
              onClick={() => {
                onGridQuestions(row.uuid)
              }}
            >
              <FaClipboardList />
            </Button>
            <Button
              color="danger"
              onClick={() => {
                onDelete(row.uuid)
              }}
            >
              <FaRemoveFormat />
            </Button>
          </>
        )
      },
    },
  ]
  const onGridQuestions = (row) => {
    history.push("/admin/quiz/" + row.uuid + "/questions")
  }
  const onTambah = () => {
    history.push("/admin/quiz/tambah")
  }
  const onEdit = (uuid) => {
    history.push("/admin/quiz/" + uuid)
  }
  const onDelete = (uuid) => {
    QuizApi.delete(uuid)
      .then((res) => {})
      .finally(() => {
        setReloadGrid(true)
      })
  }
  const onRequest = (page) => {
    return QuestionApi.list(params.uuid_quiz, page, keyWord)
  }
  return (
    <Card>
      <CardHeader>
        Admin Questions
        <Button onClick={onTambah} style={{ float: "right" }}>
          <FaPlus color="primary"></FaPlus> Question Baru
        </Button>
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg={4}></Col>
          <Col lg={8}>
            <RemoteBootstrapDataTable
              reloadGrid={true}
              setReloadGrid={setReloadGrid}
              columns={columns}
              onPaging={onRequest}
            ></RemoteBootstrapDataTable>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
export default CustomComponent
