import { Button, Card, CardBody, CardHeader } from "react-bootstrap"
import RemoteBootstrapDataTable from "../../Components/RemoteBootstrapDataTable"
import {
  FaArrowLeft,
  FaCalendar,
  FaEdit,
  FaEye,
  FaPlus,
  FaRemoveFormat,
} from "react-icons/fa"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
const CustomComponent = () => {
  const history = useHistory()
  const onBack = () => {
    history.push("/admin/quiz")
  }
  return (
    <Card>
      <CardHeader>
        Form Admin Quiz
        <Button onClick={onBack} style={{ float: "right" }}>
          <FaArrowLeft color="primary"></FaArrowLeft> Kembali
        </Button>
      </CardHeader>
      <CardBody>Detail</CardBody>
    </Card>
  )
}
export default CustomComponent
