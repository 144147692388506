import { ApiCall } from "./api"

const listQuiz = async (page) => {
  return await ApiCall.get(`/admin/quiz?page=` + page).then((res) => {
    return res
  })
}
const editQuiz = async (id) => {
  return await ApiCall.get(`/admin/quiz/${id}/edit`).then((res) => {
    return res
  })
}
const updateQuiz = async (id, fields) => {
  const formData = new FormData()
  Object.keys(fields || {}).forEach((item) => {
    formData.append(item, fields[item])
  })
  formData.append("_method", "PATCH")
  return await ApiCall.post(`/admin/quiz/${id}`, formData, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  }).then((res) => {
    return res
  })
}
const deleteQuiz = async (id) => {
  return await ApiCall.delete(`/admin/quiz/${id}`).then((res) => {
    return res
  })
}
const addQuiz = async (formData) => {
  await ApiCall.post(`/admin/quiz`, formData).then((res) => {
    return res
  })
}
export const QuizApi = {
  list: listQuiz,
  edit: editQuiz,
  add: addQuiz,
  update: updateQuiz,
  delete: deleteQuiz,
}
