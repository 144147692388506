import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import Perkalian from "./Perkalian"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import Homepage from "./Homepage"
import RandomMultiply from "./RandomMultiply"
import AdminGridQuiz from "./Admin/Quiz/Grid"
import AdminFormTambahQuiz from "./Admin/Quiz/FormTambah"
import AdminFormEditQuiz from "./Admin/Quiz/FormEdit"
import AdminGridQuestionsQuiz from "./Admin/Questions/Grid"

const routes = [
  {
    path: "/perkalian-10-detik",
    component: Perkalian,
  },
  {
    path: "/perkalian-min-max",
    component: RandomMultiply,
  },
  {
    path: "/admin/quiz",
    component: AdminGridQuiz,
  },
  {
    path: "/admin/quiz/tambah",
    component: AdminFormTambahQuiz,
  },
  {
    path: "/admin/quiz/:uuid_quiz",
    component: AdminFormEditQuiz,
  },
  {
    path: "/admin/quiz/:uuid_quiz/questions",
    component: AdminGridQuestionsQuiz,
  },
]
ReactDOM.render(
  <>
    <BrowserRouter>
      <Switch>
        {routes.map((r, i) => {
          return (
            <Route
              key={i}
              exact
              path={r.path}
              render={() => {
                return <r.component />
              }}
            ></Route>
          )
        })}
        <Route
          path="/**"
          render={() => {
            return <Homepage />
          }}
        ></Route>
      </Switch>
    </BrowserRouter>
  </>,
  document.getElementById("root")
)
