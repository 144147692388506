import moment from "moment"
import { useEffect } from "react"
import { useState } from "react"
import { Badge } from "react-bootstrap"
import { Info } from "react-feather"

const Timer = ({ start, cek }) => {
  const [counter, setCounter] = useState(null)
  const [temp, setTemp] = useState(120)
  const [timer, setTimer] = useState("00:00:00")
  const [waktu, setWaktu] = useState("")

  useEffect(() => {
    setCounter(0)
  }, [])
  useEffect(() => {
    if (start) {
      var oneSecInterval = setInterval(() => {
        setCounter(counter + 1)
      }, 1000)
    }
    setWaktu(moment().format("YY-MM-DD hh:mm:ss"))
    return () => {
      clearInterval(oneSecInterval)
    }
  }, [counter, start])

  useEffect(() => {
    if (start) {
      setCounter(0)
    }
  }, [start])

  useEffect(() => {
    setTimer(time_convert(counter))
  }, [counter])

  const time_convert = (num) => {
    // Calculate the number of hours by dividing num by 60 and rounding down
    var hours = Math.floor(num / 3600)

    // Calculate the remaining minutes by taking the remainder when dividing num by 60
    var minutes_ = num % 3600
    var minutes = Math.floor(minutes_ / 60)

    var seconds = minutes_ % 60

    // Return the result as a string in the format "hours:minutes"
    return (
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0")
    )
  }

  /*
  useEffect(() => {
    if (start) {
      const interval = setInterval(() => {
        setCounter(counter + 1)
        setTimer(time_convert(counter))
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    } else {
      setCounter(0)
    }
  }, [counter, start])
  */
  return (
    <div>
      Timer{" "}
      <Badge>
        {timer} - Waktu : {waktu}
      </Badge>
    </div>
  )
}

export default Timer
